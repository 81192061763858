
import Vue from 'vue';

type NoData = Record<string, never>;

type IconName = 'arrow' |
'checkbox_border' |
'checkbox_active' |
'round_cheched' |
'round' |
'otc' |
'star_empty' |
'star_filled' |
'arrow_down' |
'copy' |
'arrow_updown' |
'pencil_small' |
'cross' |
'arrow_right' |
'arrow_left' |
'trash' |
'clean' |
'search' |
'faq' |
'input_arrow_up' |
'input_arrow_down' |
'multiaccount_order' |
'futures_switch' |
'info' |
'check' |
'wrench' |
'reload' |
'blocked' |
'arrow_right_big' |
'qr' |
'calendar' |
'download' |
'plus' |
'sort_arrow' |
'user' |
'eye_opened' |
'eye_closed' |
'email_totp' |
'open_in_new' |
'key' |
'check_mark' |
'cogwheel' |
'clock' |
'user_pending' |
'alert' |
'open_routing' |
'light_on' |
'light_off' |
'sign_out' |
'code_totp' |
'terminal' |
'portfolio' |
'wallets' |
'workspace' |
'defi' |
'statement' |
'earn' |
'chat' |
'bell' |
'palette' |
'presets' |
'save' |
'snack_error' |
'snack_success' |
'snack_tech' |
'snack_warn' |
'group' |
'manager' |
'table_blocked' |
'user_blocked' |
'triangle' |
'wallet' |
'link' |
'sandwich' |
'onboarding' |
'sys' |
'table_user' |
'question_mark' |
'switch_mode';

export { IconName };

export default Vue.extend<NoData, NoData, NoData, NoData>({
    name: 'Icons',
});
