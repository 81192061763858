import { actionCreator, mutationCreator } from 'Store/utils';
import FuturesApi from 'Apis/Futures';
import PlaceOrderRequest, { IPlaceOrderRequest } from 'Entities/futuresOrderRegistrar/PlaceOrderRequest';
import TradingApi from 'Apis/Trading';
import FuturesPositionsRequest from 'Entities/privatePresenter/FuturesPositionsRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import FuturesPosition, { IFuturesPosition } from 'Entities/privatePresenter/FuturesPosition';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import SwitchLeverageRequest from 'Entities/futuresOrderRegistrar/SwitchLeverageRequest';
import FuturesOrderPresenter, { IFuturesOrderPresenter } from 'Entities/privatePresenter/FuturesOrderPresenter';
import FuturesTrade, { IFuturesTrade } from 'Entities/privatePresenter/FuturesTrade';
import FuturesOrdersRequest from 'Entities/privatePresenter/FuturesOrdersRequest';
import FuturesTradesRequest from 'Entities/privatePresenter/FuturesTradesRequest';
import FuturesFundingRecordsRequest from 'Entities/privatePresenter/FuturesFundingRecordsRequest';
import FuturesFundingRecord from 'Entities/privatePresenter/FuturesFundingRecord';
import CancelOrderRequest from 'Entities/orderRegistrator/CancelOrderRequest';
import { deepEqual } from 'Lib/utils/DeepEqual';

export enum QUANTITY_TYPES {
    base = 'base',
    contract = 'contract',
}

export enum SUPPORTED_DIRECTIONS {
    long = 'LONG',
    short = 'SHORT',
}

export enum FUTURES_MODES {
    hedge = 'HEDGE',
    oneWay = 'ONE_WAY',
}

enum SUPPORTED_ARRAY_NAMES {
    quickBalancesPositions = 'quickBalancesPositions',
    tablePositions = 'tablePositions',
}

export const resolveOrderType = (type: string): string => {
    switch (type) {
        case 'MARKET': {
            return 'Market';
        }
        case 'MARKET_STOP': {
            return 'Stop Market';
        }
        case 'LIMIT_STOP': {
            return 'Stop Limit';
        }
        case 'LIMIT': {
            return 'Limit';
        }
        case 'TAKE_PROFIT': {
            return 'Take Profit';
        }
        case 'STOP_LOSS': {
            return 'Stop Loss';
        }
        default: {
            return type
                .replace('_', ' ')
                .toLowerCase()
                .capitalize();
        }
    }
};

const state = {
    isSubscribedToPositionsUpdates: '',
    isSubscribedToQuickBalancesPositionsUpdates: false,
    isSubscribedToTablePositionsUpdates: false,
    isSubscribedToLeveragesUpdates: false,

    ordersData: {
        orders: new Map<number, FuturesOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as IFuturesOrderPresenter[],
        isLoading: false,
    },
    closedOrdersData: {
        orders: new Map<number, FuturesOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as IFuturesOrderPresenter[],
        isLoading: false,
    },
    openOrdersData: {
        orders: new Map<number, FuturesOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as IFuturesOrderPresenter[],
        isLoading: false,
    },
    tradesData: {
        trades: new Map<number, FuturesTrade[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        isLoading: false,
    },
    fundingsData: {
        fundings: new Map<number, FuturesFundingRecord[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        isLoading: false,
    },

    // map key format - 'accountId, orderId'
    ordersTradesMap: new Map<string, FuturesTrade[]>(),
    newTpSlOrders: [] as FuturesOrderPresenter[],
    notificationsCache: new Map<string, Set<string>>(),
    availableLeverages: [] as number[],
    positions: [] as FuturesPosition[],
    quickBalancesPositions: [] as FuturesPosition[],
    tablePositions: [] as FuturesPosition[],
    ui: {
        positionsCurrentPage: 1,
    },
};

export type FuturesState = typeof state;

const removeDuplicatedElements = (array: FuturesPosition[], arrayName: SUPPORTED_ARRAY_NAMES, state: FuturesState) => {
    const uniquePositions: string[] = [];
    const indexesToRemove: number[] = [];

    array.forEach(({ contractSymbol, side, accountId, placementName }, index) => {
        const positionIdentifier = `${contractSymbol}${side}${accountId}${placementName}`;
        if (uniquePositions.includes(positionIdentifier)) {
            indexesToRemove.push(index);
        } else {
            uniquePositions.push(positionIdentifier);
        }
    });

    indexesToRemove.forEach((positionIndex, arrayIndex) => {
        state[arrayName].splice(positionIndex - arrayIndex, 1);
    });
};
let accountPositionsSubscribtionId = null;
let assetPositionsSubscribtionIdawait = null;
export enum FuturesMutations {
    SET_POSITIONS = 'SET_POSITIONS',
    SET_QUICK_BALANCES_POSITIONS = 'SET_QUICK_BALANCES_POSITIONS',
    SET_AVAILABLE_LEVERAGES = 'SET_AVAILABLE_LEVERAGES',
    SET_TABLE_POSITIONS = 'SET_TABLE_POSITIONS',
    SET_CURRENT_POSITIONS_PAGE = 'SET_CURRENT_POSITIONS_PAGE',
    SET_ORDERS = 'SET_ORDERS',
    SET_OPEN_ORDERS = 'SET_OPEN_ORDERS',
    SET_CLOSED_ORDERS = 'SET_CLOSED_ORDERS',
    SET_TRADES = 'SET_TRADES',
    SET_FUNDINGS = 'SET_FUNDINGS',
    SET_NOTIFICATION_TO_CACHE = 'SET_NOTIFICATION_TO_CACHE',
    SET_NEW_TP_SL_ORDERS = 'SET_NEW_TP_SL_ORDERS',
}

export const SET_POSITIONS = mutationCreator<FuturesPosition[]>('Futures', FuturesMutations.SET_POSITIONS);
export const SET_QUICK_BALANCES_POSITIONS = mutationCreator<FuturesPosition[]>('Futures', FuturesMutations.SET_QUICK_BALANCES_POSITIONS);
export const SET_AVAILABLE_LEVERAGES = mutationCreator<number[]>('Futures', FuturesMutations.SET_AVAILABLE_LEVERAGES);
export const SET_TABLE_POSITIONS = mutationCreator<FuturesPosition[]>('Futures', FuturesMutations.SET_TABLE_POSITIONS);
export const SET_CURRENT_POSITIONS_PAGE = mutationCreator<number>('Futures', FuturesMutations.SET_CURRENT_POSITIONS_PAGE);
export const SET_ORDERS = mutationCreator<Map<number, FuturesOrderPresenter[]>>('Futures', FuturesMutations.SET_ORDERS);
export const SET_OPEN_ORDERS = mutationCreator<Map<number, FuturesOrderPresenter[]>>('Futures', FuturesMutations.SET_OPEN_ORDERS);
export const SET_CLOSED_ORDERS = mutationCreator<Map<number, FuturesOrderPresenter[]>>('Futures', FuturesMutations.SET_CLOSED_ORDERS);
export const SET_TRADES = mutationCreator<Map<number, FuturesTrade[]>>('Futures', FuturesMutations.SET_TRADES);
export const SET_FUNDINGS = mutationCreator<Map<number, FuturesFundingRecord[]>>('Futures', FuturesMutations.SET_FUNDINGS);
export const SET_NOTIFICATION_TO_CACHE = mutationCreator<IFuturesOrderPresenter>('Futures', FuturesMutations.SET_NOTIFICATION_TO_CACHE);
export const SET_NEW_TP_SL_ORDERS = mutationCreator<FuturesOrderPresenter[]>('Futures', FuturesMutations.SET_NEW_TP_SL_ORDERS);

const mutations: Record<FuturesMutations, (state: FuturesState, ...args: any) => void> = {
    SET_POSITIONS(state, { payload: positions }: ReturnType<typeof SET_POSITIONS>) {
        state.positions = positions;
    },
    SET_QUICK_BALANCES_POSITIONS(state, { payload: positions }: ReturnType<typeof SET_QUICK_BALANCES_POSITIONS>) {
        state.quickBalancesPositions = positions;
    },
    SET_AVAILABLE_LEVERAGES(state, { payload: leverages }: ReturnType<typeof SET_AVAILABLE_LEVERAGES>) {
        state.availableLeverages = leverages;
    },
    SET_TABLE_POSITIONS(state, { payload: positions }: ReturnType<typeof SET_TABLE_POSITIONS>) {
        state.tablePositions = positions;
    },
    SET_CURRENT_POSITIONS_PAGE(state, { payload: pageNumber }: ReturnType<typeof SET_CURRENT_POSITIONS_PAGE>) {
        state.ui.positionsCurrentPage = pageNumber;
    },
    SET_ORDERS(state, { payload: orders }: ReturnType<typeof SET_ORDERS>) {
        state.ordersData.orders = orders;
    },
    SET_OPEN_ORDERS(state, { payload: orders }: ReturnType<typeof SET_OPEN_ORDERS>) {
        state.openOrdersData.orders = orders;
    },
    SET_CLOSED_ORDERS(state, { payload: orders }: ReturnType<typeof SET_CLOSED_ORDERS>) {
        state.closedOrdersData.orders = orders;
    },
    SET_TRADES(state, { payload: trades }: ReturnType<typeof SET_TRADES>) {
        state.tradesData.trades = trades;
    },
    SET_FUNDINGS(state, { payload: fundings }: ReturnType<typeof SET_FUNDINGS>) {
        state.fundingsData.fundings = fundings;
    },
    SET_NOTIFICATION_TO_CACHE(state, { payload: order }: ReturnType<typeof SET_NOTIFICATION_TO_CACHE>) {
        const tempMap: Map<string, Set<string>> = new Map(state.notificationsCache);
        if (tempMap.has(order.id)) {
            const tempSet = new Set(tempMap.get(order.id)!);
            tempSet.add(order.status);
            tempMap.set(order.id, tempSet);
        } else {
            tempMap.set(order.id, new Set([order.status]));
        }
        state.notificationsCache = tempMap;
    },
    SET_NEW_TP_SL_ORDERS(state, { payload: orders }: ReturnType<typeof SET_NEW_TP_SL_ORDERS>) {
        state.newTpSlOrders = orders;
    },
};

export enum FuturesActions {
    placeOrder = 'placeOrder',
    cancelOrder = 'cancelOrder',
    getPositions = 'getPositions',
    getQuickBalancesPositions = 'getQuickBalancesPositions',
    getAvailableLeverages = 'getAvailableLeverages',
    changeLeverage = 'changeLeverage',
    updatePosition = 'updatePosition',
    updateQuickBalancePosition = 'updateQuickBalancePosition',
    getTablePositions = 'getTablePositions',
    updateTablePosition = 'updateTablePosition',
    getOrders = 'getOrders',
    getClosedOrders = 'getClosedOrders',
    getOpenOrders = 'getOpenOrders',
    getTrades = 'getTrades',
    updateOrders = 'updateOrders',
    updateOrder = 'updateOrder',
    updateClosedOrder = 'updateClosedOrder',
    updateOpenOrder = 'updateOpenOrder',
    updateTrade = 'updateTrade',
    getFundings = 'getFundings',
    getOrderTrades = 'getOrderTrades',
    updateOrdersTradesBySocket = 'updateOrdersTradesBySocket',
    updateNewTpSlOrdersBySocket = 'updateNewTpSlOrdersBySocket',
}

export const placeOrder = actionCreator<IPlaceOrderRequest>('Futures', FuturesActions.placeOrder);
export const cancelOrder = actionCreator<string>('Futures', FuturesActions.cancelOrder);
export const getPositions = actionCreator</* placement */ string>('Futures', FuturesActions.getPositions);
export const getQuickBalancesPositions = actionCreator<undefined>('Futures', FuturesActions.getQuickBalancesPositions);
export const getAvailableLeverages = actionCreator<undefined>('Futures', FuturesActions.getAvailableLeverages);
export const changeLeverage = actionCreator<number>('Futures', FuturesActions.changeLeverage);
export const updatePosition = actionCreator<IFuturesPosition>('Futures', FuturesActions.updatePosition);
export const updateQuickBalancePosition = actionCreator<IFuturesPosition>('Futures', FuturesActions.updateQuickBalancePosition);
export const getTablePositions = actionCreator<undefined>('Futures', FuturesActions.getTablePositions);
export const updateTablePosition = actionCreator<IFuturesPosition>('Futures', FuturesActions.updateTablePosition);
export const getOrders = actionCreator<{ page?: number; isNew?: boolean; }>('Futures', FuturesActions.getOrders);
export const getClosedOrders = actionCreator<{ page?: number; isNew?: boolean; }>('Futures', FuturesActions.getClosedOrders);
export const getOpenOrders = actionCreator<{ page?: number; isNew?: boolean; }>('Futures', FuturesActions.getOpenOrders);
export const getTrades = actionCreator<{ page?: number; isNew?: boolean; }>('Futures', FuturesActions.getTrades);
export const updateOrders = actionCreator<IFuturesOrderPresenter>('Futures', FuturesActions.updateOrders);
export const updateOrder = actionCreator<IFuturesOrderPresenter>('Futures', FuturesActions.updateOrder);
export const updateClosedOrder = actionCreator<IFuturesOrderPresenter>('Futures', FuturesActions.updateClosedOrder);
export const updateOpenOrder = actionCreator<IFuturesOrderPresenter>('Futures', FuturesActions.updateOpenOrder);
export const updateTrade = actionCreator<IFuturesTrade>('Futures', FuturesActions.updateTrade);
export const getFundings = actionCreator<{ page?: number; isNew?: boolean; }>('Futures', FuturesActions.getFundings);
export const getOrderTrades = actionCreator<{ accountId: string; orderId: string; }>('Futures', FuturesActions.getOrderTrades);
export const updateOrdersTradesBySocket = actionCreator<IFuturesTrade>('Futures', FuturesActions.updateOrdersTradesBySocket);
export const updateNewTpSlOrdersBySocket = actionCreator<IFuturesOrderPresenter>('Futures', FuturesActions.updateNewTpSlOrdersBySocket);

const actions = {
    async placeOrder({ dispatch, commit }, { payload: request }: ReturnType<typeof placeOrder>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data: order } = await FuturesApi.privateFuturesPlaceOrder(new PlaceOrderRequest(request));
            let tempOrder: any = order.serialize();
            tempOrder = { ...tempOrder, balanceId: '', initiatorId: '', initiatorType: '', orderType: request.type };
            await dispatch(updateOrders(tempOrder, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during placing order', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },

    async cancelOrder({ dispatch }, { payload: id }: ReturnType<typeof cancelOrder>) {
        try {
            await FuturesApi.privateFuturesCancelOrder(new CancelOrderRequest({
                id,
            }));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch(
                    'Notificator/showErrorNotification',
                    error.data ? error.data.message : 'Error cancelling TP/SL order',
                    { root: true },
                );
            }
        }
    },

    async getPositions({ state, dispatch, commit, rootGetters }, { payload: placement }: ReturnType<typeof getPositions>) {
        if (!state.isSubscribedToPositionsUpdates) {
            accountPositionsSubscribtionId = await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getPositions(placement, true));
                },
            }, { root: true });
            assetPositionsSubscribtionIdawait = dispatch('VuexEventListener/addActionListener', {
                type: 'Assets/setQuotationAsset',
                callback: async () => {
                    await dispatch(getPositions(placement, true));
                },
            }, { root: true });
            state.isSubscribedToPositionsUpdates = placement;
        } else if (state.isSubscribedToPositionsUpdates !== placement) {
            await dispatch('VuexEventListener/removeActionListener', {
                type: 'Accounts/setActiveAccount',
                id: accountPositionsSubscribtionId,
            }, { root: true });
            await dispatch('VuexEventListener/removeActionListener', {
                type: 'Assets/setQuotationAsset',
                id: assetPositionsSubscribtionIdawait,
            }, { root: true });
            accountPositionsSubscribtionId = await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getPositions(placement, true));
                },
            }, { root: true });
            assetPositionsSubscribtionIdawait = await dispatch('VuexEventListener/addActionListener', {
                type: 'Assets/setQuotationAsset',
                callback: async () => {
                    await dispatch(getPositions(placement, true));
                },
            }, { root: true });
        }
        if (!rootGetters['Accounts/activeAccountID'] || !rootGetters['Placements/activeTerminalPlacementName']) {
            return;
        }
        let allPositions: FuturesPosition[] = [];
        const { data: positions, headers } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            placementName: rootGetters['Placements/activeTerminalPlacementName'],
            quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
            includeTotal: true,
            page: 1,
            perPage: 100,
        }), true);
        allPositions = [...positions];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (typeof totalPages === 'number' && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraPositions } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
                        accountId: rootGetters['Accounts/activeAccountID'],
                        placementName: rootGetters['Placements/activeTerminalPlacementName'],
                        quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                        page: i,
                        perPage: 100,
                    }));
                    allPositions = [...allPositions, ...extraPositions];
                }
            }
        }
        commit(SET_POSITIONS(allPositions, true));
    },

    async getQuickBalancesPositions({ state, dispatch, commit, rootGetters }) {
        if (!state.isSubscribedToQuickBalancesPositionsUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getQuickBalancesPositions(undefined, true));
                },
            }, { root: true });
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Balances/setAccountPortfolioActivePlacementId',
                callback: async () => {
                    await dispatch(getQuickBalancesPositions(undefined, true));
                },
            }, { root: true });
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Assets/setQuotationAsset',
                callback: async () => {
                    await dispatch(getQuickBalancesPositions(undefined, true));
                },
            }, { root: true });
            state.isSubscribedToQuickBalancesPositionsUpdates = true;
        }
        if (!rootGetters['Accounts/activeAccountID'] || !rootGetters['Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID']) {
            return;
        }
        let allPositions: FuturesPosition[] = [];
        const { data: positions, headers } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            placementName: rootGetters['Placements/getPlacementNameById'](rootGetters['Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID']),
            quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
            includeTotal: true,
            page: 1,
            perPage: 100,
        }), true);
        allPositions = [...positions];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (typeof totalPages === 'number' && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraPositions } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
                        accountId: rootGetters['Accounts/activeAccountID'],
                        placementName: rootGetters['Placements/getPlacementNameById'](rootGetters['Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID']),
                        quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                        page: i,
                        perPage: 100,
                    }));
                    allPositions = [...allPositions, ...extraPositions];
                }
            }
        }
        commit(SET_QUICK_BALANCES_POSITIONS(allPositions, true));
    },

    async getAvailableLeverages({ state, commit, dispatch, rootGetters }) {
        if (!state.isSubscribedToLeveragesUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Placements/setActiveTerminalPlacementId',
                callback: async () => {
                    await dispatch(getAvailableLeverages(undefined, true));
                },
            }, { root: true });
            await dispatch('VuexEventListener/addActionListener', {
                type: 'AssetPairs/setActiveTerminalAssetPairId',
                callback: async () => {
                    await dispatch(getAvailableLeverages(undefined, true));
                },
            }, { root: true });
            state.isSubscribedToLeveragesUpdates = true;
        }

        const activeAssetPair = rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR'];
        if (!activeAssetPair) {
            return;
        }
        const leverages = activeAssetPair.placementLeverages;
        if (!leverages) {
            return;
        }
        commit(SET_AVAILABLE_LEVERAGES(leverages.map((l) => Number(l.level)).sort((a, b) => a - b), true));
    },

    async changeLeverage({ commit, dispatch, rootGetters }, { payload: leverage }: ReturnType<typeof changeLeverage>) {
        const accountId = rootGetters['Accounts/activeAccountID'];
        const placementName = rootGetters['Placements/activeTerminalPlacementName'];
        const contractSymbol = rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL'];
        if (!accountId || !placementName || !contractSymbol || !leverage) {
            await dispatch('Notificator/showErrorNotification', 'Error during changing leverage, please try again later', { root: true });
            return;
        }

        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await FuturesApi.privateFuturesSwitchLeverage(new SwitchLeverageRequest({
                accountId,
                placementName,
                contractSymbol,
                leverage,
            }));
            await dispatch('Notificator/showSuccessNotification', 'Leverage has been successfully changed', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during changing leverage, please try again later', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },

    async updatePosition({ state, rootGetters }, { payload: position }: ReturnType<typeof updatePosition>) {
        const { positions } = state;
        const foundedPositionIndex = positions.findIndex((p) => p.contractSymbol === position.contractSymbol
                                            && p.accountId === position.accountId
                                            && p.placementName === position.placementName
                                            && p.side === position.side);
        if (foundedPositionIndex === -1) {
            if (rootGetters['Accounts/activeAccountID'] === position.accountId && rootGetters['Placements/activeTerminalPlacementName'] === position.placementName) {
                const { data: newPosition } = await TradingApi.privateFuturesGetPosition(new FuturesPositionsRequest({
                    placementName: position.placementName,
                    accountId: position.accountId,
                    contractSymbol: position.contractSymbol,
                    // eslint-disable-next-line no-nested-ternary
                    side: position.side === 'SHORT' ? 'SHORT' : position.side === 'LONG' ? 'LONG' : undefined,
                }));
                state.positions.unshift(newPosition);
            }
            return;
        }
        const tempPosition = positions[foundedPositionIndex].serialize();
        tempPosition.entryPrice = position.entryPrice;
        tempPosition.leverage = String(position.leverage);
        tempPosition.liquidationPrice = position.liquidationPrice;
        tempPosition.quantity = position.quantity;
        tempPosition.unrealizedProfit = position.unrealizedProfit;
        tempPosition.markPrice = position.markPrice;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (position.quantity !== 0 && position.quantity !== '0') {
            state.positions.splice(foundedPositionIndex, 1, new FuturesPosition(tempPosition));
        } else {
            state.positions.splice(foundedPositionIndex, 1);
        }
    },

    async updateQuickBalancePosition({ state, rootGetters }, { payload: position }: ReturnType<typeof updateQuickBalancePosition>) {
        const positions = state.quickBalancesPositions;
        const foundedPositionIndex = positions.findIndex((p) => p.contractSymbol === position.contractSymbol
            && p.accountId === position.accountId
            && p.placementName === position.placementName
            && p.side === position.side);
        if (foundedPositionIndex === -1) {
            if (rootGetters['Accounts/activeAccountID'] === position.accountId && rootGetters['Placements/getPlacementNameById'](rootGetters['Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID']) === position.placementName) {
                const { data: newPosition } = await TradingApi.privateFuturesGetPosition(new FuturesPositionsRequest({
                    placementName: position.placementName,
                    accountId: position.accountId,
                    contractSymbol: position.contractSymbol,
                    // eslint-disable-next-line no-nested-ternary
                    side: position.side === 'SHORT' ? 'SHORT' : position.side === 'LONG' ? 'LONG' : undefined,
                }));
                state.quickBalancesPositions.unshift(newPosition);
            }
            removeDuplicatedElements(state.quickBalancesPositions, SUPPORTED_ARRAY_NAMES.quickBalancesPositions, state);
            return;
        }
        const tempPosition = positions[foundedPositionIndex].serialize();
        tempPosition.entryPrice = position.entryPrice;
        tempPosition.leverage = String(position.leverage);
        tempPosition.liquidationPrice = position.liquidationPrice;
        tempPosition.quantity = position.quantity;
        tempPosition.unrealizedProfit = position.unrealizedProfit;
        tempPosition.profitPercent = position.profitPercent;
        tempPosition.quoteQuantity = position.quoteQuantity;
        tempPosition.markPrice = position.markPrice;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (position.quantity !== 0 && position.quantity !== '0') {
            state.quickBalancesPositions.splice(foundedPositionIndex, 1, new FuturesPosition(tempPosition));
        } else {
            state.quickBalancesPositions.splice(foundedPositionIndex, 1);
        }
        removeDuplicatedElements(state.quickBalancesPositions, SUPPORTED_ARRAY_NAMES.quickBalancesPositions, state);
    },

    async getTablePositions({ commit, dispatch, rootGetters }) {
        if (!state.isSubscribedToTablePositionsUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getTablePositions(undefined, true));
                },
            }, { root: true });
            state.isSubscribedToTablePositionsUpdates = true;
        }
        if (!rootGetters['Accounts/activeAccountID']) {
            return;
        }
        let allPositions: FuturesPosition[] = [];
        const { data: positions, headers } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            includeTotal: true,
            perPage: 100,
            page: 1,
        }), true);
        allPositions = [...positions];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraPositions } = await TradingApi.privateFuturesGetPositions(new FuturesPositionsRequest({
                        accountId: rootGetters['Accounts/activeAccountID'],
                        perPage: 100,
                        page: i,
                    }));
                    allPositions = [...allPositions, ...extraPositions];
                }
            }
        }
        commit(SET_TABLE_POSITIONS(allPositions, true));
    },

    async updateTablePosition({ state, rootGetters }, { payload: position }: ReturnType<typeof updateTablePosition>) {
        const { tablePositions } = state;
        const foundedPositionIndex = tablePositions.findIndex((p) => p.contractSymbol === position.contractSymbol
            && p.accountId === position.accountId
            && p.placementName === position.placementName
            && p.side === position.side);
        if (foundedPositionIndex === -1) {
            if (rootGetters['Accounts/activeAccountID'] === position.accountId) {
                const { data: newPosition } = await TradingApi.privateFuturesGetPosition(new FuturesPositionsRequest({
                    placementName: position.placementName,
                    accountId: position.accountId,
                    contractSymbol: position.contractSymbol,
                    // eslint-disable-next-line no-nested-ternary
                    side: position.side === 'SHORT' ? 'SHORT' : position.side === 'LONG' ? 'LONG' : undefined,
                }));
                state.tablePositions.unshift(newPosition);
            }
            removeDuplicatedElements(state.tablePositions, SUPPORTED_ARRAY_NAMES.tablePositions, state);
            return;
        }
        const tempPosition = tablePositions[foundedPositionIndex].serialize();
        tempPosition.entryPrice = position.entryPrice;
        tempPosition.leverage = String(position.leverage);
        tempPosition.liquidationPrice = position.liquidationPrice;
        tempPosition.quantity = position.quantity;
        tempPosition.unrealizedProfit = position.unrealizedProfit;
        tempPosition.profitPercent = position.profitPercent;
        tempPosition.quoteQuantity = position.quoteQuantity;
        tempPosition.markPrice = position.markPrice;
        tempPosition.margin = position.margin;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (position.quantity !== 0 && position.quantity !== '0') {
            state.tablePositions.splice(foundedPositionIndex, 1, new FuturesPosition(tempPosition));
        } else {
            state.tablePositions.splice(foundedPositionIndex, 1);
        }
        removeDuplicatedElements(state.tablePositions, SUPPORTED_ARRAY_NAMES.tablePositions, state);
    },

    async getOrders({ state, dispatch, rootState, commit }, { payload: { page, isNew } }: ReturnType<typeof getOrders>) {
        if (!state.ordersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getOrders({ page: 1, isNew: true }, true));
                },
            }, { root: true });
            state.ordersData.isSubscribedToUpdates = true;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit(SET_ORDERS(new Map(), true));
        }
        if (!isNew && state.ordersData.orders.has(page)) {
            return;
        }

        try {
            state.ordersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.ordersData.perPage,
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.ordersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.ordersData.orders);
            temp.set(page, orders);
            commit(SET_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } catch {
            const temp = new Map(state.ordersData.orders);
            temp.set(page, []);
            commit(SET_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } finally {
            state.ordersData.isLoading = false;
        }
    },

    async getClosedOrders({ state, dispatch, rootState, commit }, { payload: { page, isNew } }: ReturnType<typeof getClosedOrders>) {
        if (!state.closedOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getClosedOrders({ page: 1, isNew: true }, true));
                },
            }, { root: true });
            state.closedOrdersData.isSubscribedToUpdates = true;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit(SET_CLOSED_ORDERS(new Map(), true));
        }
        if (!isNew && state.closedOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.closedOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.closedOrdersData.perPage,
                statuses: ['FILLED', 'CANCELED', 'REJECTED'],
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.closedOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.closedOrdersData.orders);
            temp.set(page, orders);
            commit(SET_CLOSED_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } catch {
            const temp = new Map(state.closedOrdersData.orders);
            temp.set(page, []);
            commit(SET_CLOSED_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } finally {
            state.closedOrdersData.isLoading = false;
        }
    },

    async getOpenOrders({ state, dispatch, rootState, commit }, { payload: { page, isNew } }: ReturnType<typeof getOpenOrders>) {
        if (!state.openOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getOpenOrders({ page: 1, isNew: true }, true));
                },
            }, { root: true });
            state.openOrdersData.isSubscribedToUpdates = true;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit(SET_OPEN_ORDERS(new Map(), true));
        }
        if (!isNew && state.openOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.openOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateFuturesGetOrders(new FuturesOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.openOrdersData.perPage,
                statuses: ['REGISTERED', 'PARTIALY_FILLED', 'CREATED', 'PLACED'],
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.openOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.openOrdersData.orders);
            temp.set(page, orders);
            commit(SET_OPEN_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } catch {
            const temp = new Map(state.openOrdersData.orders);
            temp.set(page, []);
            commit(SET_OPEN_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
        } finally {
            state.openOrdersData.isLoading = false;
        }
    },

    async getTrades({ state, dispatch, rootState, commit }, { payload: { page, isNew } }: ReturnType<typeof getTrades>) {
        if (!state.tradesData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getTrades({ isNew: true, page: 1 }, true));
                },
            }, { root: true });
            state.tradesData.isSubscribedToUpdates = true;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit(SET_TRADES(new Map(), true));
        }
        if (!isNew && state.tradesData.trades.has(page)) {
            return;
        }

        try {
            state.tradesData.isLoading = true;
            const {
                data: trades,
                headers,
            } = await TradingApi.privateFuturesGetTrades(new FuturesTradesRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.tradesData.perPage,
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.tradesData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.tradesData.trades);
            temp.set(page, trades);
            commit(SET_TRADES(temp as Map<number, FuturesTrade[]>, true));
        } catch {
            const temp = new Map(state.tradesData.trades);
            temp.set(page, []);
            commit(SET_TRADES(temp as Map<number, FuturesTrade[]>, true));
        } finally {
            state.tradesData.isLoading = false;
        }
    },

    async getOrderTrades({ state }, { payload: { orderId, accountId } }: ReturnType<typeof getOrderTrades>) {
        try {
            if (state.ordersTradesMap.has(`${accountId}, ${orderId}`)) {
                return;
            }

            const { data: trades } = await TradingApi.privateFuturesGetTrades(new FuturesTradesRequest({
                accountId,
                orderId,
            }));
            const tempMap = new Map(state.ordersTradesMap);
            tempMap.set(`${accountId}, ${orderId}`, trades);
            state.ordersTradesMap = tempMap;
        } catch {
            const tempMap = new Map(state.ordersTradesMap);
            tempMap.set(`${accountId}, ${orderId}`, []);
            state.ordersTradesMap = tempMap;
        }
    },

    async getFundings({ state, dispatch, rootState, commit }, { payload: { isNew, page } }: ReturnType<typeof getFundings>) {
        if (!state.fundingsData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch(getFundings({ page: 1, isNew: true }, true));
                },
            }, { root: true });

            await dispatch('VuexEventListener/addActionListener', {
                type: 'Assets/setQuotationAsset',
                callback: async () => {
                    await dispatch(getFundings({ page: 1, isNew: true }, true));
                },
            }, { root: true });
            state.fundingsData.isSubscribedToUpdates = true;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit(SET_FUNDINGS(new Map(), true));
        }
        if (!isNew && state.fundingsData.fundings.has(page)) {
            return;
        }

        try {
            state.fundingsData.isLoading = true;
            const {
                data: fundings,
                headers,
            } = await TradingApi.privateFuturesGetFundingRecords(new FuturesFundingRecordsRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.fundingsData.perPage,
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.fundingsData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.fundingsData.fundings);
            temp.set(page, fundings);
            commit(SET_FUNDINGS(temp as Map<number, FuturesFundingRecord[]>, true));
        } catch {
            const temp = new Map(state.fundingsData.fundings);
            temp.set(page, []);
            commit(SET_FUNDINGS(temp as Map<number, FuturesFundingRecord[]>, true));
        } finally {
            state.fundingsData.isLoading = false;
        }
    },

    async updateOrders({ state, dispatch, commit }, { payload: order }: ReturnType<typeof updateOrder>) {
        if (!state.notificationsCache.has(order.id) || !state.notificationsCache.get(order.id)?.has(order.status)) {
            // eslint-disable-next-line no-nested-ternary
            dispatch(`Notificator/show${order.status.toLowerCase() === 'rejected'
                ? 'Error'
                : order.status.toLowerCase() === 'canceled'
                    ? 'Warning'
                    : 'Success'}Notification`, `Order ${order.id} has been ${order.status.capitalize()}`, { root: true });
        }
        commit(SET_NOTIFICATION_TO_CACHE(order, true));
        const allActiveOrders: FuturesOrderPresenter[][] = state.openOrdersData.orders.values();
        const hasSameActiveOrder = allActiveOrders
            .some((ordersArray) => ordersArray.some((o) => o.id === order.id));

        const tempOrdersQueue = [...state.ordersData.updatesQueue];
        tempOrdersQueue.push(order);
        state.ordersData.updatesQueue = tempOrdersQueue;
        await dispatch(updateOrder(order, true));
        await dispatch(updateNewTpSlOrdersBySocket(order, true));
        if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
            const tempClosedOrdersQueue = [...state.closedOrdersData.updatesQueue];
            tempClosedOrdersQueue.push(order);
            state.closedOrdersData.updatesQueue = tempClosedOrdersQueue;
            await dispatch(updateClosedOrder(order, true));
        }
        if (
            (order.status.toLowerCase() !== 'filled' && order.status.toLowerCase() !== 'canceled' && order.status.toLowerCase() !== 'rejected')
            || hasSameActiveOrder
        ) {
            const tempOpenOrdersQueue = [...state.closedOrdersData.updatesQueue];
            tempOpenOrdersQueue.push(order);
            state.openOrdersData.updatesQueue = tempOpenOrdersQueue;
            await dispatch(updateOpenOrder(order, true));
        }
    },

    async updateOrder({ state, dispatch, commit }, { payload: order }: ReturnType<typeof updateOrder>) {
        try {
            if (state.ordersData.updatesQueue.length > 0 && deepEqual(state.ordersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.ordersData.orders.keys()).forEach((key) => {
                    const array = state.ordersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new FuturesOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.ordersData.orders);
                        temp.set(key, [...array]);
                        commit(SET_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.ordersData.orders).keys());
                    commit(SET_ORDERS(new Map(), true));
                    tempArrayOfKeys.forEach((key) => {
                        dispatch(getOrders({ page: key as number }, true));
                    });
                }

                const tempQueue = [...state.ordersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.ordersData.updatesQueue = tempQueue;
                if (state.ordersData.updatesQueue.length > 0) {
                    await dispatch(updateOrder(state.ordersData.updatesQueue[0], true));
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },

    async updateClosedOrder({ state, dispatch, commit }, { payload: order }: ReturnType<typeof updateClosedOrder>) {
        try {
            if (state.closedOrdersData.updatesQueue.length > 0 && deepEqual(state.closedOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.closedOrdersData.orders.keys()).forEach((key) => {
                    const array = state.closedOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new FuturesOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.closedOrdersData.orders);
                        temp.set(key, [...array]);
                        commit(SET_CLOSED_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.closedOrdersData.orders).keys());
                    commit(SET_CLOSED_ORDERS(new Map(), true));
                    tempArrayOfKeys.forEach((key) => {
                        dispatch(getClosedOrders({ page: key as number }, true));
                    });
                }

                const tempQueue = [...state.closedOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.closedOrdersData.updatesQueue = tempQueue;
                if (state.closedOrdersData.updatesQueue.length > 0) {
                    await dispatch(updateClosedOrder(state.closedOrdersData.updatesQueue[0], true));
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },

    async updateOpenOrder({ state, dispatch, commit }, { payload: order }: ReturnType<typeof updateOpenOrder>) {
        try {
            if (state.openOrdersData.updatesQueue.length > 0 && deepEqual(state.openOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.openOrdersData.orders.keys()).forEach((key) => {
                    const array = state.openOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
                            array.splice(index, 1);
                        } else {
                            const data = array![index].serialize();
                            array![index] = new FuturesOrderPresenter({ ...data, ...order });
                        }
                        const temp = new Map(state.openOrdersData.orders);
                        temp.set(key, [...array]);
                        commit(SET_OPEN_ORDERS(temp as Map<number, FuturesOrderPresenter[]>, true));
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.openOrdersData.orders).keys());
                    commit(SET_OPEN_ORDERS(new Map(), true));
                    tempArrayOfKeys.forEach((key) => {
                        dispatch(getOpenOrders({ page: key as number }, true));
                    });
                }

                const tempQueue = [...state.openOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.openOrdersData.updatesQueue = tempQueue;
                if (state.openOrdersData.updatesQueue.length > 0) {
                    await dispatch(updateOpenOrder(state.openOrdersData.updatesQueue[0], true));
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },

    async updateTrade({ state, dispatch, commit }, { payload: trade }: ReturnType<typeof updateTrade>) {
        try {
            let flag = false;
            Array.from(state.tradesData.trades.keys()).forEach((key) => {
                const array = state.tradesData.trades.get(key);
                const index = array?.findIndex((t) => t.id === trade.id);
                if (index !== undefined && index !== -1) {
                    const data = array![index].serialize();
                    array![index] = new FuturesTrade({ ...data, ...trade });
                    const temp = new Map(state.tradesData.trades);
                    temp.set(key, [...array]);
                    commit(SET_TRADES(temp as Map<number, FuturesTrade[]>, true));
                    flag = true;
                }
            });
            if (!flag) {
                const tempArrayOfKeys = Array.from(new Map(state.tradesData.trades).keys());
                commit(SET_TRADES(new Map(), true));
                tempArrayOfKeys.forEach((key) => {
                    dispatch(getTrades({ page: key as number }, true));
                });
            }
        } catch {
            // code crushed because of indexes error
        }
    },

    updateOrdersTradesBySocket({ state }, { payload: trade }: ReturnType<typeof updateOrdersTradesBySocket>) {
        try {
            if (!state.ordersTradesMap.has(`${trade.accountId}, ${trade.orderId}`)) {
                return;
            }

            const tempArray = state.ordersTradesMap.get(`${trade.accountId}, ${trade.orderId}`);
            const index = tempArray.findIndex((t) => t.id === trade.id);
            if (index !== undefined && index !== -1) {
                tempArray.splice(index, 1, new FuturesTrade({ ...tempArray[index].serialize(), ...trade }));
            } else {
                tempArray.push(new FuturesTrade(trade));
            }
            const tempMap = new Map(state.ordersTradesMap);
            tempMap.set(`${trade.accountId}, ${trade.orderId}`, [...tempArray]);
            state.ordersTradesMap = tempMap;
        } catch (error) {
            console.log('err', error);
            // not enough data to create FuturesTrade entity
        }
    },
    async updateNewTpSlOrdersBySocket({ state, commit }, { payload: order }: ReturnType<typeof updateNewTpSlOrdersBySocket>) {
        if (order.orderType !== 'STOP_LOSS' && order.orderType !== 'TAKE_PROFIT') {
            return;
        }

        const orders = [...state.newTpSlOrders];
        const index = orders.findIndex(({ id }) => id === order.id);
        if (index !== -1) {
            orders.splice(index, 1, new FuturesOrderPresenter({
                ...orders[index].serialize(),
                ...order,
            }));
        } else {
            orders.push(new FuturesOrderPresenter(order));
        }

        commit(SET_NEW_TP_SL_ORDERS(orders, true));
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
