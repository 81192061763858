<template>
    <div :class="s.container">
        <div v-if="mode === modes.SELECT_ACTION">
            <Button
                id="addNewAccountButton"
                :button-disabled="!isKycVerified"
                button-type="infoNoBackground"
                @click="mode = modes.ADD_NEW_ACCOUNT"
                wide
            >
                <template>
                    Add new account
                </template>
            </Button>
        </div>
        <form v-else-if="mode === modes.ADD_NEW_ACCOUNT" @submit.prevent="createAccount">
            <TextInput
                title="Account Name"
                @input-text="newAccountName = $event; errors.name = false"
                :class="s.mbL"
                :value="newAccountName"
                :has-error="errors.name"
                error-text="Use 3-15 characters: letters, digits, or underscores"
            />
            <TextInput
                title="Description"
                placeholder="Optional"
                @input-text="newAccountDescription = $event; errors.description = false"
                is-text-area
                :value="newAccountDescription"
                :has-error="errors.description"
                error-text="Max 150 characters: letters, digits, _, spaces, or -"
            />
            <div :class="s.buttons">
                <Button
                    @click="mode = modes.SELECT_ACTION"
                    button-type="danger"
                    wide
                >
                    <template>
                        cancel
                    </template>
                </Button>
                <Button
                    button-type="primary"
                    is-submit
                    wide
                >
                    <template>
                        add
                    </template>
                </Button>
            </div>
        </form>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/dist/validators.min';

import { AccountRules, AccountNameMask } from 'Models/accounts';
import Button from 'Control/Button.vue';
import TextInput from 'Control/TextInput.vue';

const modes = {
    SELECT_ACTION: 'SELECT_ACTION',
    ADD_NEW_ACCOUNT: 'ADD_NEW_ACCOUNT',
};

export default {
    name: 'AccountsSettings',
    components: {
        Button,
        TextInput,
    },
    data() {
        return {
            accountNameMinLength: AccountRules.name.minLength,
            accountNameMaxLength: AccountRules.name.maxLength,
            accountDescriptionMinLength: AccountRules.description.minLength,
            accountDescriptionMaxLength: AccountRules.description.maxLength,
            AccountNameMask,
            modes,
            mode: modes.SELECT_ACTION,
            newAccountData: {
                name: '',
                description: '',
            },
            errors: {
                name: false,
                description: false,
            },
            nameRegExp: /^[a-zA-Z0-9_]{3,15}$/,
            descriptionRegExp: /^(?:[a-zA-Z0-9_](\s|-?)){0,150}$/,
        };
    },
    computed: {
        newAccountName: {
            set(value) {
                this.$v.newAccountData.name.$model = value;
            },
            get() {
                return this.newAccountData.name;
            },
        },
        newAccountDescription: {
            set(value) {
                this.$v.newAccountData.description.$model = value;
            },
            get() {
                return this.newAccountData.description;
            },
        },

        isOpen() {
            return this.mode === modes.ADD_NEW_ACCOUNT;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
    validations() {
        return {
            newAccountData: {
                name: {
                    required,
                    minLength: minLength(this.accountNameMinLength),
                    maxLength: maxLength(this.accountNameMaxLength),
                },
                description: {
                    minLength: minLength(this.accountDescriptionMinLength),
                    maxLength: maxLength(this.accountDescriptionMaxLength),
                },
            },
        };
    },
    methods: {
        createAccount() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                const isNameValid = this.nameRegExp.test(this.newAccountData.name);
                if (!isNameValid) {
                    this.errors.name = true;
                    return;
                }

                const newAccountData = {
                    name: this.newAccountData.name,
                };

                if (this.newAccountData.description !== '') {
                    const isDescriptionValid = this.descriptionRegExp.test(this.newAccountData.description);
                    if (!isDescriptionValid) {
                        this.errors.description = true;
                        return;
                    }
                    newAccountData.description = this.newAccountData.description;
                }

                this.$store
                    .dispatch('Accounts/Management/createAccount', newAccountData)
                    .then(() => {
                        this.$store.dispatch(
                            'Notificator/showSuccessNotification',
                            `Account ${newAccountData.name} was successfully created`,
                        );

                        this.newAccountData.name = '';
                        this.newAccountData.description = '';

                        this.mode = modes.SELECT_ACTION;
                    })
                    .catch(() => {
                        this.$store.dispatch(
                            'Notificator/showErrorNotification',
                            'During creating something went wrong. Please, retry later',
                        );
                    });
            } else {
                if (this.$v.newAccountData.name.$invalid) {
                    this.errors.name = true;
                }
                if (this.$v.newAccountData.description.$invalid) {
                    this.errors.description = true;
                }
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: block;
    padding: 0;
    position: static;
    background: none;
    & .buttons {
        display: flex;
        justify-content: space-between;
        column-gap: var(--m-s);
    }
    & .mbL {
        margin-bottom: var(--m-l);
    }
}
</style>
