import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import AccountsApi from 'Apis/Accounts';
import TotalBalancesRequest from 'Entities/privatePresenter/TotalBalancesRequest';
import TotalBalance, { ITotalBalance } from 'Entities/privatePresenter/TotalBalance';

const state = {
    totalBalances: [] as TotalBalance[],
    isSubscribedToUpdates: false,
};

export type MarginState = typeof state;

export enum MarginMutations {
    SET_TOTAL_BALANCES = 'SET_TOTAL_BALANCES',
    SET_IS_SUBSCRIBED_TO_UPDATES = 'SET_IS_SUBSCRIBED_TO_UPDATES',
}

export const SET_TOTAL_BALANCES = mutationCreator<TotalBalance[]>('Margin', MarginMutations.SET_TOTAL_BALANCES);
export const SET_IS_SUBSCRIBED_TO_UPDATES = mutationCreator<boolean>('Margin', MarginMutations.SET_IS_SUBSCRIBED_TO_UPDATES);

const mutations: Record<MarginMutations, (state: MarginState, ...args: any[]) => void> = {
    SET_TOTAL_BALANCES(state, { payload: totalBalances }: ReturnType<typeof SET_TOTAL_BALANCES>) {
        state.totalBalances = totalBalances;
    },
    SET_IS_SUBSCRIBED_TO_UPDATES(state, { payload: value }: ReturnType<typeof SET_IS_SUBSCRIBED_TO_UPDATES>) {
        state.isSubscribedToUpdates = value;
    },
};

export enum MarginActions {
    getTotalBalances = 'getTotalBalances',
    updateTotalBalancesBySocket = 'updateTotalBalancesBySocket',
}

export const getTotalBalances = actionCreator<undefined>('Margin', MarginActions.getTotalBalances);
export const updateTotalBalancesBySocket = actionCreator<ITotalBalance>('Margin', MarginActions.updateTotalBalancesBySocket);

const actions: Record<MarginActions, (Action<MarginState, any>)> = {
    async getTotalBalances({ state, rootState, dispatch, commit }) {
        try {
            if (!state.isSubscribedToUpdates) {
                commit(SET_IS_SUBSCRIBED_TO_UPDATES(true, true));
                await dispatch('VuexEventListener/addActionListener', {
                    type: 'Accounts/setActiveAccount',
                    callback: async () => {
                        await dispatch(getTotalBalances(undefined, true));
                    },
                }, { root: true });
            }

            const accountId = rootState.Accounts.activeAccountID;
            if (!accountId) {
                return;
            }

            const { data: totalBalances } = await AccountsApi.privateGetTotalBalances(new TotalBalancesRequest({
                accountId,
            }));
            commit(SET_TOTAL_BALANCES(totalBalances, true));
        } catch {
            // api error
        }
    },
    updateTotalBalancesBySocket({ state, commit }, { payload: balance }: ReturnType<typeof updateTotalBalancesBySocket>) {
        const tempArray = [...state.totalBalances];
        const index = tempArray.findIndex(({ placementGroupTag }) => placementGroupTag === balance.placementGroupTag);
        if (index !== -1) {
            tempArray.splice(index, 1, new TotalBalance(balance));
        }
        commit(SET_TOTAL_BALANCES(tempArray, true));
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
