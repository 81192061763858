// This file was autogenerated. Please do not change.
export interface IGetPositionModeResponse {
    mode?: ('HEDGE' | 'ONE_WAY');
}

export default class GetPositionModeResponse {
    readonly _mode: ('HEDGE' | 'ONE_WAY') | undefined;

    /**
     * Example: HEDGE
     */
    get mode(): ('HEDGE' | 'ONE_WAY') | undefined {
        return this._mode;
    }

    constructor(props: IGetPositionModeResponse) {
        if (props.mode) {
            this._mode = props.mode;
        }
    }

    serialize(): IGetPositionModeResponse {
        const data: IGetPositionModeResponse = {
        };
        if (typeof this._mode !== 'undefined') {
            data.mode = this._mode;
        }
        return data;
    }

    toJSON(): IGetPositionModeResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
