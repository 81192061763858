import ApiError from 'Entities/ApiError';
import CancelOrderRequest from 'Entities/futuresOrderRegistrar/CancelOrderRequest';
import EmptyResult from 'Entities/futuresOrderRegistrar/EmptyResult';
import FuturesOrder from 'Entities/futuresOrderRegistrar/FuturesOrder';
import GetPositionModeRequest from 'Entities/futuresOrderRegistrar/GetPositionModeRequest';
import GetPositionModeResponse from 'Entities/futuresOrderRegistrar/GetPositionModeResponse';
import OrderCostRequest from 'Entities/futuresOrderRegistrar/OrderCostRequest';
import OrderCostResponse from 'Entities/futuresOrderRegistrar/OrderCostResponse';
import PlaceOrderRequest from 'Entities/futuresOrderRegistrar/PlaceOrderRequest';
import SwitchLeverageRequest from 'Entities/futuresOrderRegistrar/SwitchLeverageRequest';
import SwitchLeverageResponse from 'Entities/futuresOrderRegistrar/SwitchLeverageResponse';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class FuturesApi {
    static async privateFuturesCancelOrder(cancelorderrequest: CancelOrderRequest, withHeaders?: boolean): Promise<{ data: EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...cancelorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesCancelOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/futures/cancelOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(cancelorderrequest.serialize()),
        });
        if (res.status === 204) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Futures/privateFuturesCancelOrder', { response: res, data: result, fields: [JSON.stringify({ cancelorderrequest })] });
    }

    static async privateFuturesGetPositionMode(getpositionmoderequest: GetPositionModeRequest, withHeaders?: boolean): Promise<{ data: GetPositionModeResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getpositionmoderequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesGetPositionMode', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/futures/getPositionMode`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getpositionmoderequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new GetPositionModeResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Futures/privateFuturesGetPositionMode', { response: res, data: result, fields: [JSON.stringify({ getpositionmoderequest })] });
    }

    static async privateFuturesOrderCost(ordercostrequest: OrderCostRequest, withHeaders?: boolean): Promise<{ data: OrderCostResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...ordercostrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesOrderCost', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/futures/orderCost`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(ordercostrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new OrderCostResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Futures/privateFuturesOrderCost', { response: res, data: result, fields: [JSON.stringify({ ordercostrequest })] });
    }

    static async privateFuturesPlaceOrder(placeorderrequest: PlaceOrderRequest, withHeaders?: boolean): Promise<{ data: FuturesOrder; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placeorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesPlaceOrder', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/futures/placeOrder`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placeorderrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new FuturesOrder(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Futures/privateFuturesPlaceOrder', { response: res, data: result, fields: [JSON.stringify({ placeorderrequest })] });
    }

    static async privateFuturesSwitchLeverage(switchleveragerequest: SwitchLeverageRequest, withHeaders?: boolean): Promise<{ data: SwitchLeverageResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...switchleveragerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateFuturesSwitchLeverage', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/futures/switchLeverage`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(switchleveragerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SwitchLeverageResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Futures/privateFuturesSwitchLeverage', { response: res, data: result, fields: [JSON.stringify({ switchleveragerequest })] });
    }
}
