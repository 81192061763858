import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import Balance, { IBalance } from 'Entities/privatePresenter/Balance';
import Getters from 'Store/getters';
import { UPDATE_BALANCE } from 'Store/v2/Defi';
import AccountsApi from 'Apis/Accounts';
import BalancesRequest from 'Entities/privatePresenter/BalancesRequest';
import ApiError from 'Entities/ApiError';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import { updateActiveTerminalPlacementAssetsList } from 'Store/v2/Assets';
import { UnifiedExchangesTags } from 'Store/v2/Transfer';

export interface IAggregatedBalance {
    accountId: string;
    assetSymbol: string;
    assetType: string;
    assetPrecision: number;
    total: number;
    free: number;
    hold: number;
    positionsHold: number;
    totalEquivalent: number;
    freeEquivalent: number;
    holdEquivalent: number;
    blockchainName: string | undefined;
    placements: any;
}

export interface IPlacementsAggregatedBalance {
    placementName: string;
    assetsBalances: {
        total: number;
        free: number;
        hold: number;
        positionsHold: number;
        assetSymbol: string;
        blockchainName?: string;
        totalEquivalent: number;
        holdEquivalent: number;
        freeEquivalent: number;
        assetType: string;
        placementName: string;
    }[];
}

export interface IPlacementsBalance {
    totalEquivalent: number;
    freeEquivalent: number;
    holdEquivalent: number;
    placementId: number;
    placementName: string;
    balances: Balance[];
}

const state = {
    balances: [] as Balance[],
    accountPortfolioActivePlacementId: 1 as number,
    multiAccounts: false as boolean,
};

export type BalancesState = typeof state;

export enum BalancesGetters {
    GET_BALANCES = 'GET_BALANCES',
    GET_ACTIVE_ACCOUNT_BALANCES = 'GET_ACTIVE_ACCOUNT_BALANCES',
    GET_BALANCE_BY_ID = 'GET_BALANCE_BY_ID',
    GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS = 'GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS',
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID = 'GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID',
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME = 'GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME',
    GET_ACCOUNT_PLACEMENT_BALANCE = 'GET_ACCOUNT_PLACEMENT_BALANCE',
    GET_TOTAL_ACCOUNT_SCORE = 'GET_TOTAL_ACCOUNT_SCORE',
    GET_FREE_ACCOUNT_SCORE = 'GET_FREE_ACCOUNT_SCORE',
    GET_HOLD_ACCOUNT_SCORE = 'GET_HOLD_ACCOUNT_SCORE',
    GET_ACTIVE_ACCOUNT_TOTAL_SCORE = 'GET_ACTIVE_ACCOUNT_TOTAL_SCORE',
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID = 'GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID',
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES = 'GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES',
    GET_BALANCE_FREE_EQUIVALENT = 'GET_BALANCE_FREE_EQUIVALENT',
    GET_BALANCE_TOTAL_EQUIVALENT = 'GET_BALANCE_TOTAL_EQUIVALENT',
    GET_BALANCE_HOLD_EQUIVALENT = 'GET_BALANCE_HOLD_EQUIVALENT',
    GET_BALANCE_SUMMARY_HOLD_EQUIVALENT = 'GET_BALANCE_SUMMARY_HOLD_EQUIVALENT',
    GET_ACCOUNT_PORTFOLIO_TOTAL_SCORE = 'GET_ACCOUNT_PORTFOLIO_TOTAL_SCORE',
    GET_AGGREGATED_BALANCES = 'GET_AGGREGATED_BALANCES',
    GET_HIGH_AGGREGATED_BALANCE_SCORE = 'GET_HIGH_AGGREGATED_BALANCE_SCORE',
    GET_BALANCES_BY_PLACEMENTS = 'GET_BALANCES_BY_PLACEMENTS',
    GET_PLACEMENT_BALANCES = 'GET_PLACEMENT_BALANCES',
    GET_ACCOUNT_TOTAL_QUOTED = 'GET_ACCOUNT_TOTAL_QUOTED',
    GET_PLACEMENTS_AGGREGATED_BALANCES = 'GET_PLACEMENTS_AGGREGATED_BALANCES',
}

type GettersReturn<G extends { [key in BalancesGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[BalancesGetters]> };

interface Getters {
    GET_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>) => Balance[];
    GET_ACTIVE_ACCOUNT_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Balance[];
    GET_BALANCE_BY_ID: (state: BalancesState, getters: GettersReturn<Getters>) => (balanceId: string) => Balance | undefined;
    GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS: (state: BalancesState, getters: GettersReturn<Getters>) => (placementId: number, symbol: string, blockchain?: string) => Balance | undefined;
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID: (state: BalancesState, getters: GettersReturn<Getters>) => (placementId: number) => Balance[];
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME: (state: BalancesState, getters: GettersReturn<Getters>) => (placementName: string) => Balance[];
    GET_ACCOUNT_PLACEMENT_BALANCE: (state: BalancesState, getters: GettersReturn<Getters>) => (accountId: string, placementId: number, assetSymbol: string) => Balance | undefined;
    GET_TOTAL_ACCOUNT_SCORE: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (accountId: string) => number;
    GET_FREE_ACCOUNT_SCORE: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (accountId: string) => number;
    GET_HOLD_ACCOUNT_SCORE: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (accountId: string) => number;
    GET_ACTIVE_ACCOUNT_TOTAL_SCORE: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number;
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID: (state: BalancesState, getters: GettersReturn<Getters>) => number;
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>) => Balance[];
    GET_BALANCE_FREE_EQUIVALENT: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (balance: Balance) => number;
    GET_BALANCE_TOTAL_EQUIVALENT: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (balance: Balance) => number;
    GET_BALANCE_HOLD_EQUIVALENT: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (balance: Balance) => number;
    GET_BALANCE_SUMMARY_HOLD_EQUIVALENT: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (balance: Balance) => number;
    GET_ACCOUNT_PORTFOLIO_TOTAL_SCORE: (state: BalancesState, getters: GettersReturn<Getters>) => number;
    GET_AGGREGATED_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => IAggregatedBalance[];
    GET_HIGH_AGGREGATED_BALANCE_SCORE: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number;
    GET_BALANCES_BY_PLACEMENTS: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => IPlacementsBalance[];
    GET_PLACEMENT_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>) => (givenPlacementId: number) => IPlacementsBalance | undefined;
    GET_ACCOUNT_TOTAL_QUOTED: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (id: string) => number;
    GET_ACCOUNT_FREE_QUOTED: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (id: string) => number;
    GET_ACCOUNT_HOLD_QUOTED: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (id: string) => number;
    GET_PLACEMENTS_AGGREGATED_BALANCES: (state: BalancesState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => IPlacementsAggregatedBalance[];
}

const getters: Getters = {
    GET_BALANCES(state) {
        return state.balances;
    },
    GET_ACTIVE_ACCOUNT_BALANCES(state, getters, rootState, rootGetters) {
        const isActiveAccountOwned = rootGetters['Accounts/isActiveAccountOwned'];

        if (state.multiAccounts) {
            if (isActiveAccountOwned) {
                return state.balances.filter((balance) => {
                    const isExist = rootGetters['Accounts/ownAccounts'].find((item) => item.id === balance.accountId);
                    if (isExist) {
                        return balance.id;
                    }
                    return false;
                });
            }

            return state.balances.filter((balance) => {
                const isExist = rootGetters['Accounts/manageAccounts'].find((item) => item.id === balance.accountId);

                if (isExist) {
                    return balance.id;
                }
                return false;
            });
        }

        return state.balances.filter((balance) => balance.accountId === rootGetters['Accounts/activeAccountID']);
    },
    GET_BALANCE_BY_ID(state) {
        return (balanceId) => {
            return state.balances.find((balance) => balance.id === balanceId);
        };
    },
    GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS(state, getters) {
        return (placementId, symbol, blockchain) => {
            if (blockchain) {
                return (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).find((balance) => (symbol ? balance.assetSymbol.toLowerCase() === symbol.toLowerCase() : true) && balance.placementId === placementId && balance.blockchainName === blockchain);
            }
            return (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).find((balance) => (symbol ? balance.assetSymbol.toLowerCase() === symbol.toLowerCase() : true) && balance.placementId === placementId);
        };
    },
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID(state, getters) {
        return (placementId) => {
            return (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).filter((balance) => balance.placementId === placementId);
        };
    },
    GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME(state, getters) {
        return (placementName) => {
            return (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).filter((balance) => balance.placementName === placementName);
        };
    },
    GET_ACCOUNT_PLACEMENT_BALANCE(state) {
        return (accountId, placementId, assetSymbol) => {
            return state.balances.find((balance) => balance.accountId === accountId
                && balance.placementId === placementId
                && balance.assetSymbol === assetSymbol);
        };
    },
    GET_TOTAL_ACCOUNT_SCORE(state, getters, rootState, rootGetters) {
        return (accountId) => {
            return rootGetters['Accounts/getAccountById'](accountId)?.total || 0;
        };
    },
    GET_FREE_ACCOUNT_SCORE(state, getters, rootState, rootGetters) {
        return (accountId) => {
            return rootGetters['Accounts/getAccountById'](accountId)?.free || 0;
        };
    },
    GET_HOLD_ACCOUNT_SCORE(state, getters, rootState, rootGetters) {
        return (accountId) => {
            return rootGetters['Accounts/getAccountById'](accountId)?.hold || 0;
        };
    },
    GET_ACTIVE_ACCOUNT_TOTAL_SCORE(state, getters, rootState, rootGetters) {
        return (getters.GET_TOTAL_ACCOUNT_SCORE as any)(rootGetters['Accounts/activeAccountID']);
    },
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID(state) {
        return state.accountPortfolioActivePlacementId;
    },
    GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES(state, getters) {
        return (getters.GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_ID as any)(getters.GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID);
    },
    GET_BALANCE_FREE_EQUIVALENT(state, getters, rootState, rootGetters) {
        return (balance) => {
            if (!balance.quotations) {
                return 0;
            }
            const quotationIndex: number = balance.quotationAssetSymbols?.findIndex((symbol) => symbol === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']) ?? 0;
            if (quotationIndex !== -1) {
                return balance.free * (balance as any).quotations[quotationIndex];
            }
            return 0;
        };
    },
    GET_BALANCE_TOTAL_EQUIVALENT(state, getters, rootState, rootGetters) {
        return (balance) => {
            if (!balance.quotations) {
                return 0;
            }
            const quotationIndex: number = balance.quotationAssetSymbols?.findIndex((symbol) => symbol === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']) ?? 0;
            if (quotationIndex !== -1) {
                return balance.total * (balance as any).quotations[quotationIndex];
            }
            return 0;
        };
    },
    GET_BALANCE_HOLD_EQUIVALENT(state, getters, rootState, rootGetters) {
        return (balance) => {
            if (!balance.quotations) {
                return 0;
            }
            const quotationIndex: number = balance.quotationAssetSymbols?.findIndex((symbol) => symbol === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']) ?? 0;
            if (quotationIndex !== -1) {
                return balance.hold * (balance as any).quotations[quotationIndex];
            }
            return 0;
        };
    },
    GET_BALANCE_SUMMARY_HOLD_EQUIVALENT(state, getters, rootState, rootGetters) {
        return (balance) => {
            if (!balance.quotations) {
                return 0;
            }
            const quotationIndex: number = balance.quotationAssetSymbols?.findIndex((symbol) => symbol === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']) ?? 0;
            if (quotationIndex !== -1) {
                return (balance.hold + balance.positionsHold) * (balance as any).quotations[quotationIndex];
            }
            return 0;
        };
    },
    GET_ACCOUNT_PORTFOLIO_TOTAL_SCORE(state, getters) {
        return (getters.GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_BALANCES as Balance[]).reduce((sum, balance) => {
            if (balance.free === 0) {
                return Number(sum);
            }
            if (!balance.quotationAssetSymbols || !balance.quotations) {
                return Number(sum);
            }
            return sum + (getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance);
        }, 0);
    },
    GET_AGGREGATED_BALANCES(state, getters, rootState, rootGetters) {
        const result: IAggregatedBalance[] = [];
        let currentAggregatedBalance: IAggregatedBalance | undefined;
        let currentBalancePlacementName: string | undefined;

        (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).forEach((balance) => {
            currentAggregatedBalance = result.find(
                (aggregatedBalance) => aggregatedBalance.assetSymbol === balance.assetSymbol,
            );
            currentBalancePlacementName = rootGetters['Placements/getPlacementNameById'](balance.placementId);
            const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(currentBalancePlacementName?.toUpperCase() ?? '');
            const currentPlacementType = rootState.Placements.placements.find(({ name }) => name.toUpperCase() === (currentBalancePlacementName?.toUpperCase() ?? ''))?.type;
            if (!UnifiedExchangesTags.has(currentPlacementTag) || currentPlacementType === 'crypto-spot') {
                if (currentAggregatedBalance) {
                    currentAggregatedBalance.total += Number(balance.total);
                    currentAggregatedBalance.free += Number(balance.free);
                    currentAggregatedBalance.hold += Number(balance.hold);
                    currentAggregatedBalance.positionsHold += Number(balance.positionsHold ?? 0);

                    currentAggregatedBalance.totalEquivalent += Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance));
                    currentAggregatedBalance.freeEquivalent += Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(balance));
                    currentAggregatedBalance.holdEquivalent += Number((getters.GET_BALANCE_HOLD_EQUIVALENT as any)(balance));

                    if (currentBalancePlacementName === 'Single Broker') {
                        if (currentAggregatedBalance.placements[`${currentBalancePlacementName}:${balance.blockchainName}`]) {
                            const oldBalance = currentAggregatedBalance.placements[`${currentBalancePlacementName}:${balance.blockchainName}`];
                            currentAggregatedBalance.placements[`${currentBalancePlacementName}:${balance.blockchainName}`] = new Balance({
                                ...oldBalance.serialize(),
                                free: oldBalance.free + balance.free,
                                hold: oldBalance.hold + balance.hold,
                                total: oldBalance.total + balance.total,
                                positionsHold: oldBalance.positionsHold + balance.positionsHold,
                            });
                        } else {
                            currentAggregatedBalance.placements[`${currentBalancePlacementName}:${balance.blockchainName}`] = balance;
                        }
                    } else if (currentAggregatedBalance.placements[currentBalancePlacementName!]) {
                        const oldBalance = currentAggregatedBalance.placements[currentBalancePlacementName!];
                        currentAggregatedBalance.placements[currentBalancePlacementName!] = new Balance({
                            ...oldBalance.serialize(),
                            free: oldBalance.free + balance.free,
                            hold: oldBalance.hold + balance.hold,
                            total: oldBalance.total + balance.total,
                            positionsHold: oldBalance.positionsHold + balance.positionsHold,
                        });
                    } else {
                        currentAggregatedBalance.placements[currentBalancePlacementName!] = balance;
                    }
                } else {
                    let placementsPayload;
                    // eslint-disable-next-line no-unused-expressions
                    currentBalancePlacementName === 'Single Broker' ? placementsPayload = `${currentBalancePlacementName}:${balance.blockchainName}` : placementsPayload = currentBalancePlacementName;
                    result.push({
                        accountId: rootGetters['Accounts/activeAccountID'],

                        assetSymbol: balance.assetSymbol,
                        assetType: balance.assetType,
                        assetPrecision: rootGetters['Assets/GET_ASSET_DEEP_BY_SYMBOL'](balance.assetSymbol),

                        total: balance.total,
                        free: balance.free,
                        hold: balance.hold,
                        positionsHold: balance.positionsHold ?? 0,

                        totalEquivalent: Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance)),
                        freeEquivalent: Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(balance)),
                        holdEquivalent: Number((getters.GET_BALANCE_HOLD_EQUIVALENT as any)(balance)),

                        blockchainName: balance.blockchainName,

                        placements: {
                            [placementsPayload]: balance,
                        },
                    });
                }
            }
        });
        return result;
    },
    GET_HIGH_AGGREGATED_BALANCE_SCORE(state, getters) {
        return Math.max(...(getters.GET_AGGREGATED_BALANCES as IAggregatedBalance[]).map((balance) => Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance))));
    },
    GET_BALANCES_BY_PLACEMENTS(state, getters, rootState, rootGetters) {
        const result: IPlacementsBalance[] = [];
        let currentPlacementName: string | undefined;
        let currentPlacementBalance: IPlacementsBalance | undefined;

        (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).forEach((balance) => {
            const currentPlacementType = rootState.Placements.placements.find(({ name }) => name === balance.placementName)?.type;
            const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(balance.placementName.toUpperCase());
            if (!UnifiedExchangesTags.has(currentPlacementTag ?? '') || currentPlacementType === 'crypto-spot') {
                currentPlacementName = rootGetters['Placements/getPlacementNameById'](balance.placementId);
                currentPlacementBalance = result.find(({ placementName }) => placementName === currentPlacementName);

                if (currentPlacementBalance) {
                    currentPlacementBalance.totalEquivalent += Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance));
                    currentPlacementBalance.freeEquivalent += Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(balance));
                    currentPlacementBalance.holdEquivalent += Number((getters.GET_BALANCE_HOLD_EQUIVALENT as any)(balance));

                    currentPlacementBalance.balances.push(balance);
                } else {
                    result.push({
                        totalEquivalent: Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(balance)),
                        freeEquivalent: Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(balance)),
                        holdEquivalent: Number((getters.GET_BALANCE_HOLD_EQUIVALENT as any)(balance)),

                        placementId: balance.placementId,
                        placementName: currentPlacementName ?? '',
                        balances: [balance],
                    });
                }
            }
        });

        return result;
    },
    GET_PLACEMENT_BALANCES(state, getters) {
        return (givenPlacementId) => {
            return (getters.GET_BALANCES_BY_PLACEMENTS as IPlacementsBalance[]).find(({ placementId }) => placementId === givenPlacementId);
        };
    },
    GET_ACCOUNT_TOTAL_QUOTED(state, _, rootState, rootGetters) {
        return (id) => {
            return state.balances
                .reduce((accum, current) => {
                    const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(current.placementName.toUpperCase());
                    const currentPlacementType = rootState.Placements.placements.find(({ name }) => name.toUpperCase() === current.placementName.toUpperCase())?.type;
                    if (
                        current.accountId !== id
                        || (
                            UnifiedExchangesTags.has(currentPlacementTag)
                            && currentPlacementType !== 'crypto-spot'
                        )
                    ) {
                        return accum;
                    }

                    const selectedQuotationIndex = current.quotationAssetSymbols?.findIndex((q) => q === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
                    if (typeof selectedQuotationIndex === 'number' && selectedQuotationIndex !== -1) {
                        return accum + (Number(current.quotations ? current.quotations[selectedQuotationIndex] : 0) * Number(current.total));
                    }
                    return accum;
                }, 0);
        };
    },
    GET_ACCOUNT_FREE_QUOTED(state, _, rootState, rootGetters) {
        return (id) => {
            return state.balances
                .reduce((accum, current) => {
                    const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(current.placementName.toUpperCase());
                    const currentPlacementType = rootState.Placements.placements.find(({ name }) => name.toUpperCase() === current.placementName.toUpperCase())?.type;
                    if (
                        current.accountId !== id
                        || (
                            UnifiedExchangesTags.has(currentPlacementTag)
                            && currentPlacementType !== 'crypto-spot'
                        )
                    ) {
                        return accum;
                    }

                    const selectedQuotationIndex = current.quotationAssetSymbols?.findIndex((q) => q === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
                    if (typeof selectedQuotationIndex === 'number' && selectedQuotationIndex !== -1) {
                        return accum + (Number(current.quotations ? current.quotations[selectedQuotationIndex] : 0) * Number(current.free));
                    }
                    return accum;
                }, 0);
        };
    },
    GET_ACCOUNT_HOLD_QUOTED(state, _, rootState, rootGetters) {
        return (id) => {
            return state.balances
                .reduce((accum, current) => {
                    const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(current.placementName.toUpperCase());
                    const currentPlacementType = rootState.Placements.placements.find(({ name }) => name.toUpperCase() === current.placementName.toUpperCase())?.type;
                    if (
                        current.accountId !== id
                        || (
                            UnifiedExchangesTags.has(currentPlacementTag)
                            && currentPlacementType !== 'crypto-spot'
                        )
                    ) {
                        return accum;
                    }

                    const selectedQuotationIndex = current.quotationAssetSymbols?.findIndex((q) => q === rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL']);
                    if (typeof selectedQuotationIndex === 'number' && selectedQuotationIndex !== -1) {
                        const quotation = Number(current.quotations ? current.quotations[selectedQuotationIndex] : 0);
                        return accum + (quotation * Number(current.hold)) + (quotation * Number(current.positionsHold ?? 0));
                    }
                    return accum;
                }, 0);
        };
    },
    GET_PLACEMENTS_AGGREGATED_BALANCES(_, getters, rootState) {
        const result: IPlacementsAggregatedBalance[] = [];
        (getters.GET_ACTIVE_ACCOUNT_BALANCES as Balance[]).forEach((b) => {
            const balanceIndex = result.findIndex(({ placementName }) => placementName === b.placementName);
            const currentPlacementTag = rootState.Placements.placementNamesToPlacementTags.get(b.placementName.toUpperCase());
            const currentPlacementType = rootState.Placements.placements.find(({ name }) => name.toUpperCase() === b.placementName.toUpperCase())?.type;
            if (!UnifiedExchangesTags.has(currentPlacementTag) || currentPlacementType === 'crypto-spot') {
                if (balanceIndex !== -1) {
                    const assetBalanceIndex = result[balanceIndex]
                        .assetsBalances
                        .findIndex(({ assetSymbol, blockchainName }) => assetSymbol === b.assetSymbol
                            && blockchainName === b.blockchainName);
                    if (assetBalanceIndex !== -1) {
                        let temp = { ...result[balanceIndex].assetsBalances[assetBalanceIndex] };
                        temp = {
                            ...temp,
                            free: temp.free + Number(b.free),
                            total: temp.total + Number(b.total),
                            hold: temp.hold + Number(b.hold),
                            positionsHold: temp.positionsHold + Number(b.positionsHold),
                            freeEquivalent: temp.freeEquivalent + Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(b)),
                            totalEquivalent: temp.totalEquivalent + Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(b)),
                            holdEquivalent: temp.holdEquivalent + Number((getters.GET_BALANCE_SUMMARY_HOLD_EQUIVALENT as any)(b)),
                        };
                        result[balanceIndex].assetsBalances[assetBalanceIndex] = temp;
                    } else {
                        result[balanceIndex].assetsBalances.push({
                            total: Number(b.total),
                            free: Number(b.free),
                            hold: Number(b.hold),
                            positionsHold: Number(b.positionsHold),
                            assetSymbol: b.assetSymbol,
                            blockchainName: b.blockchainName,
                            totalEquivalent: Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(b)),
                            freeEquivalent: Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(b)),
                            holdEquivalent: Number((getters.GET_BALANCE_SUMMARY_HOLD_EQUIVALENT as any)(b)),
                            assetType: b.assetType,
                            placementName: b.placementName,
                        });
                    }
                } else {
                    result.push({
                        placementName: b.placementName,
                        assetsBalances: [{
                            total: Number(b.total),
                            free: Number(b.free),
                            hold: Number(b.hold),
                            positionsHold: Number(b.positionsHold),
                            assetSymbol: b.assetSymbol,
                            blockchainName: b.blockchainName,
                            totalEquivalent: Number((getters.GET_BALANCE_TOTAL_EQUIVALENT as any)(b)),
                            freeEquivalent: Number((getters.GET_BALANCE_FREE_EQUIVALENT as any)(b)),
                            holdEquivalent: Number((getters.GET_BALANCE_SUMMARY_HOLD_EQUIVALENT as any)(b)),
                            assetType: b.assetType,
                            placementName: b.placementName,
                        }],
                    });
                }
            }
        });
        return result;
    },
};

export enum BalancesMutations {
    SET_IS_MULTIACCOUNTS = 'SET_IS_MULTIACCOUNTS',
    SET_BALANCES = 'SET_BALANCES',
    SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID = 'SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID',
}

export const SET_IS_MULTIACCOUNTS = mutationCreator<boolean>('Balances', BalancesMutations.SET_IS_MULTIACCOUNTS);
export const SET_BALANCES = mutationCreator<Balance[]>('Balances', BalancesMutations.SET_BALANCES);
export const SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID = mutationCreator<number>('Balances', BalancesMutations.SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID);

const mutations: Record<BalancesMutations, (state: BalancesState, ...args: any) => void> = {
    SET_IS_MULTIACCOUNTS(state, { payload: isMultiaccounts }: ReturnType<typeof SET_IS_MULTIACCOUNTS>) {
        state.multiAccounts = isMultiaccounts;
    },
    SET_BALANCES(state, { payload: balances }: ReturnType<typeof SET_BALANCES>) {
        state.balances = balances;
    },
    SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID(state, { payload: placementId }: ReturnType<typeof SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID>) {
        state.accountPortfolioActivePlacementId = placementId;
    },
};

export enum BalancesActions {
    updateBalance = 'updateBalance',
    updateBalancesList = 'updateBalancesList',
    setAccountPortfolioActivePlacementId = 'setAccountPortfolioActivePlacementId',
}

export const updateBalance = actionCreator<IBalance>('Balances', BalancesActions.updateBalance);
export const updateBalancesList = actionCreator<undefined>('Balances', BalancesActions.updateBalancesList);
export const setAccountPortfolioActivePlacementId = actionCreator<number>('Balances', BalancesActions.setAccountPortfolioActivePlacementId);

const actions: Record<BalancesActions, Action<BalancesState, any>> = {
    updateBalance({ state, rootState, commit }, { payload: updatedBalance }: ReturnType<typeof updateBalance>) {
        const { assetSymbol, assetId, placementId, placementName, placementType, quotations, blockchainName, quotationAssetSymbols, total, free, hold, positionsHold, id, accountId, assetType } = updatedBalance;
        const balanceIndex = state.balances.findIndex((balance) => (balance.assetSymbol === assetSymbol
            && balance.placementId === placementId
            && balance.accountId === accountId
            && (blockchainName ? balance.blockchainName === blockchainName : true)));

        if (balanceIndex !== -1) {
            const tempBalance = state.balances[balanceIndex].serialize();
            tempBalance.total = total;
            tempBalance.free = free;
            tempBalance.hold = hold;
            tempBalance.positionsHold = positionsHold ?? '';
            state.balances.splice(balanceIndex, 1, new Balance(tempBalance));
        } else {
            const newBalance = new Balance({
                id,
                accountId,
                total,
                free,
                hold,
                positionsHold: positionsHold ?? '',
                assetSymbol,
                assetType,
                placementId,
                placementName,
                placementType,
                quotationAssetSymbols,
                quotations,
                blockchainName,
                assetId,
            });
            state.balances.push(newBalance);
        }

        const defiBalances = rootState.Defi.fromBalances;
        if (defiBalances && placementName === 'Single Broker') {
            const defiBalance = defiBalances.find((balance) => (balance.assetSymbol === assetSymbol
                && balance.placementId === placementId
                && (blockchainName ? balance.blockchainName === blockchainName : true)));
            if (defiBalance) {
                commit(UPDATE_BALANCE(new Balance({ ...defiBalance.serialize(), id, free, hold, total })), { root: true });
            } else {
                const newDefiBalance = new Balance({
                    id,
                    accountId,
                    total,
                    free,
                    hold,
                    assetSymbol,
                    assetType,
                    placementId,
                    placementName,
                    placementType,
                    quotationAssetSymbols,
                    quotations,
                    blockchainName,
                    assetId,
                    positionsHold: positionsHold ?? '',
                });
                rootState.Defi.fromBalances.push(newDefiBalance);
            }
        }
    },
    async updateBalancesList({ commit, dispatch, rootState, rootGetters }) {
        if (!rootState.User.currentUser || !rootGetters['Accounts/activeAccountID']) {
            return;
        }
        try {
            let totalBalancesList: Balance[] = [];
            const { data: balances, headers } = await AccountsApi.privateGetBalances(new BalancesRequest({
                accountIds: rootGetters['Accounts/accounts'].map(({ id }) => id),
                perPage: 100,
                page: 1,
                includeTotal: true,
            }), true);
            totalBalancesList = [...balances];
            if (headers) {
                const totalPages = parsePaginationHeaders(headers).totalPage;
                if (totalPages && totalPages > 1) {
                    for (let i = 2; i <= totalPages; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: extraBalances } = await AccountsApi.privateGetBalances(new BalancesRequest({
                            accountIds: rootGetters['Accounts/accounts'].map(({ id }) => id),
                            perPage: 100,
                            page: i,
                        }));
                        totalBalancesList = [...totalBalancesList, ...extraBalances];
                    }
                }
            }
            commit(SET_BALANCES(totalBalancesList, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting balances');
            }
        }
    },
    async setAccountPortfolioActivePlacementId({ commit, dispatch }, { payload: placementId }: ReturnType<typeof setAccountPortfolioActivePlacementId>) {
        if (placementId !== null) {
            commit(SET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID(Number(placementId), true));
            await dispatch(updateActiveTerminalPlacementAssetsList(placementId), { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
